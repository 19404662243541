import React, {Component, useEffect} from 'react';
import {BrowserRouter, HashRouter, Route, Switch} from 'react-router-dom';
import './scss/style.scss';
import './fontawesome'
import {addActiveQuestion, addArchivedQuestion, addNewQuestion, setLoggedInStatus} from "./actions";
import { connect } from "react-redux";
import Socket from '../src/services/socket'
import socketConnection from "../src/services/socket";
import Cookies from "js-cookie";
import {getRequest, getUsersSubject, getUserType, showErrorToast} from "./utils/utills";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'));
const Join = React.lazy(() => import('./views/pages/join/Join'));
const Register = React.lazy(() => import('./views/pages/register/Register'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));

// function mapDispatchToProps(dispatch) {
//   return {
//     addNewQuestion: new_question => dispatch(addNewQuestion(new_question))
//   };
// }

export const getAccessToken = () => Cookies.get('access_token')
export const getRefreshToken = () => Cookies.get('refresh_token')
export const isAuthenticated = () => !!getAccessToken()

const mapStateToProps = state => {
  return { isLoggedIn: state.isLoggedIn };
};

function mapDispatchToProps(dispatch) {
  return {
    setLoggedInStatus: isLoggedIn => dispatch(setLoggedInStatus(isLoggedIn)),
    addNewQuestion: new_question => dispatch(addNewQuestion(new_question)),
    addActiveQuestion: active_questions => dispatch(addActiveQuestion(active_questions)),
    addArchivedQuestions: archived_questions => dispatch(addArchivedQuestion(archived_questions))
  };
}


const ConnectedApp = ({isLoggedIn, ...props}) => {

  useEffect(()=>{
    if(isAuthenticated()) {
      props.setLoggedInStatus(true)
    }else{
      props.setLoggedInStatus(false)
    }
  },[])

  async function getDoubts(){

    getRequest(`/api/users/doubts/active/all`, (response)=>{

      console.log("Active Doubts: ", response.data.data.rows);
      if(response.status===200){
        response.data.data.forEach((doubt)=>{
          console.log("Active questions: ", doubt)
          doubt.to = '/inbox/active/chat/' + doubt._id;
          doubt._tag = 'CSidebarNavItem';
          doubt.name = 'CSidebarNavItem';
          props.addActiveQuestion({activeQuestions: doubt})
        })
      }
    },(error)=>{

    });

    getRequest(`/api/users/doubts/unassigned?page_size=100`, (response)=>{
      console.log("Unassigned Doubts: ", response.data.data.rows);
      if(response.status===200){
        response.data.data.rows.forEach((doubt)=>{
          console.log("Doubt: ", doubt)
          doubt.to = '/inbox/unassigned/chat/' + doubt._id;
          doubt._tag = 'CSidebarNavItem';
          doubt.name = 'CSidebarNavItem';
          props.addNewQuestion({newQuestion: doubt})
        })
      }
    },(error)=>{

    });

    getRequest(`/api/users/doubts/archieved?page_size=100`, (response)=>{
      console.log("Unassigned Doubts: ", response.data.data.rows);
      if(response.status===200){
        response.data.data.rows.forEach((doubt)=>{
          console.log("Doubt: ", doubt)
          doubt.to = '/inbox/archived/chat/' + doubt._id;
          doubt._tag = 'CSidebarNavItem';
          doubt.name = 'CSidebarNavItem';
          props.addArchivedQuestions({archivedQuestions: doubt})
        })
      }
    },(error)=>{

    });
  }

  useEffect(()=>{
    if(isLoggedIn){
      getDoubts()
      switch (getUserType()) {
        case "superuser":
          socketConnection.on('new_question_all', (newQuestion) => {
            console.log({newQuestion})
            newQuestion.to = '/inbox/unassigned/chat/' + newQuestion.id;
            newQuestion._tag = 'CSidebarNavItem';
            newQuestion.name = 'CSidebarNavItem';
            newQuestion._id = newQuestion.id;

            newQuestion.class_id = newQuestion.doubt.class_id
            newQuestion.created_at = newQuestion.doubt.created_at
            newQuestion.student_bookmark = newQuestion.doubt.student_bookmark
            newQuestion.student_id = newQuestion.doubt.student_id
            newQuestion.subject_id = newQuestion.doubt.subject_id
            newQuestion.tutor_bookmark = newQuestion.doubt.tutor_bookmark
            newQuestion.updated_at = newQuestion.doubt.updated_at
            newQuestion.status = newQuestion.doubt.status

            props.addNewQuestion({newQuestion})
          });
          break;
        case "admin":
          socketConnection.on('new_question_all', (newQuestion) => {
            console.log({newQuestion})
            newQuestion.to = '/inbox/unassigned/chat/' + newQuestion.id;
            newQuestion._tag = 'CSidebarNavItem';
            newQuestion.name = 'CSidebarNavItem';
            newQuestion._id = newQuestion.id;

            newQuestion.class_id = newQuestion.doubt.class_id
            newQuestion.created_at = newQuestion.doubt.created_at
            newQuestion.student_bookmark = newQuestion.doubt.student_bookmark
            newQuestion.student_id = newQuestion.doubt.student_id
            newQuestion.subject_id = newQuestion.doubt.subject_id
            newQuestion.tutor_bookmark = newQuestion.doubt.tutor_bookmark
            newQuestion.updated_at = newQuestion.doubt.updated_at
            newQuestion.status = newQuestion.doubt.status

            props.addNewQuestion({newQuestion})
          });
          break;
        case "supervisor":
          if(getUsersSubject()) {
            JSON.parse(getUsersSubject()).forEach((subjectId) => {

              socketConnection.on(`new_question_${subjectId}`, (newQuestion) => {
                console.log({newQuestion})
                newQuestion.to = '/inbox/unassigned/chat/' + newQuestion.id;
                newQuestion._tag = 'CSidebarNavItem';
                newQuestion.name = 'CSidebarNavItem';
                newQuestion._id = newQuestion.id;

                newQuestion.class_id = newQuestion.doubt.class_id
                newQuestion.created_at = newQuestion.doubt.created_at
                newQuestion.student_bookmark = newQuestion.doubt.student_bookmark
                newQuestion.student_id = newQuestion.doubt.student_id
                newQuestion.subject_id = newQuestion.doubt.subject_id
                newQuestion.tutor_bookmark = newQuestion.doubt.tutor_bookmark
                newQuestion.updated_at = newQuestion.doubt.updated_at
                newQuestion.status = newQuestion.doubt.status

                props.addNewQuestion({newQuestion})
              });
            })
          }
          break;
        case "tutor":
          if(getUsersSubject()) {
            JSON.parse(getUsersSubject()).forEach((subjectId) => {

              socketConnection.on(`new_question_${subjectId}`, (newQuestion) => {
                console.log({newQuestion})
                newQuestion.to = '/inbox/unassigned/chat/' + newQuestion.id;
                newQuestion._tag = 'CSidebarNavItem';
                newQuestion.name = 'CSidebarNavItem';
                newQuestion._id = newQuestion.id;

                newQuestion.class_id = newQuestion.doubt.class_id
                newQuestion.created_at = newQuestion.doubt.created_at
                newQuestion.student_bookmark = newQuestion.doubt.student_bookmark
                newQuestion.student_id = newQuestion.doubt.student_id
                newQuestion.subject_id = newQuestion.doubt.subject_id
                newQuestion.tutor_bookmark = newQuestion.doubt.tutor_bookmark
                newQuestion.updated_at = newQuestion.doubt.updated_at
                newQuestion.status = newQuestion.doubt.status

                props.addNewQuestion({newQuestion})
              });
            })
          }
          break;
        default:
          break;

      }
    }else{
      socketConnection.removeAllListeners();
    }
  },[isLoggedIn])

  return (
    <BrowserRouter>
      <React.Suspense fallback={loading}>
        <Switch>
          <Route path="/join" name="Join Page" render={props => <Join {...props}/>} />
          <Route exact path="/404" name="Page 404" render={props => <Page404 {...props}/>} />
          <Route exact path="/500" name="Page 500" render={props => <Page500 {...props}/>} />
          <Route path="/" name="Home" render={props => <TheLayout {...props}/>} />
        </Switch>
      </React.Suspense>
    </BrowserRouter>
  );
}

const App = connect(mapStateToProps,mapDispatchToProps)(ConnectedApp);

export default React.memo(App)
